import React, { createContext, useContext, useState, useEffect } from 'react';
import { useUser } from '@clerk/clerk-react';

interface ImageData {
  url: string;
  name: string;
  size: number;
  type: string;
}

interface VideoData {
  url: string;
  size: number;
  type: string;
  filename: string;
  uploadedAt: string;
}

interface Order {
  platform: any;
  user_email: string;
  id: string;
  images: ImageData[];
  videos?: VideoData[];
  requirements: string;
  deadline: string;
  status: 'pending' | 'completed';
  style?: string;
  platformUrl?: string;
}

interface VideoStyle {
  id: string;
  name: string;
  points: number;
  previewImage: string;
  description: string;
}

interface VideoContextType {
  orders: Order[];
  userOrders: Order[];
  userPoints: number;
  videoStyles: VideoStyle[];
  addOrder: (order: Order) => void;
  adduserOrder: (order: Order) => void;
  updateOrderStatus: (orderId: string, status: Order['status']) => void;
  deductPoints: (points: number) => void;
  fetchOrders: (userEmail?: string) => void;
}

const defaultVideoStyles: VideoStyle[] = [
  {
    id: 'cute-anime',
    name: '主图视频',
    points: 15,
    previewImage: 'https://images.unsplash.com/photo-1578632767115-351597cf2477',
    description: '清新可爱的动漫风格，适合萌系产品展示'
  },
  {
    id: '2.5d',
    name: '创意视频',
    points: 20,
    previewImage: 'https://images.unsplash.com/photo-1578632767115-351597cf2478',
    description: '介于写实和动漫之间的精致风格'
  }
];

const VideoContext = createContext<VideoContextType | undefined>(undefined);

export function useVideo() {
  const context = useContext(VideoContext);
  if (!context) {
    throw new Error('useVideo must be used within a VideoProvider');
  }
  return context;
}

export function VideoProvider({ children }: { children: React.ReactNode }) {
  const [allOrders, setAllOrders] = useState<Order[]>([]);
  const [userOrders, setUserOrders] = useState<Order[]>([]);
  const [userPoints, setUserPoints] = useState(100);

  const { user, isLoaded } = useUser();

  // 获取任务列表
  const fetchOrders = async (userEmail?: string) => {
    try {
      const url = userEmail ? `/api/tasks?user_email=${encodeURIComponent(userEmail)}` : '/api/tasks';
      const response = await fetch(url);
      const result = await response.json();
      
      if (result.success) {
        console.log('Raw task data:', result.data[0]);
        // 转换数据格式以匹配Order接口
        const formattedOrders = result.data.map((task: any) => ({
          id: task.template_id,
          user_email: task.user_email,
          requirements: task.description,
          deadline: task.deadline,
          status: task.status,
          images: task.images || [],
          videos: task.videos || [],
          style: task.edit_video_style_input,
          userPoints: task.user_points,
          platform: task.platform,
          platformUrl: task.platform_url,
        }));
        console.log('Formatted order:', formattedOrders[0]);
        
        // 根据是否提供userEmail来更新不同的状态
        if (userEmail) {
          setUserOrders(formattedOrders);
          if (formattedOrders.length > 0) {
            setUserPoints(formattedOrders[0].userPoints);
          }
        } else {
          setAllOrders(formattedOrders);
        }
      }
    } catch (error) {
      console.error('Error fetching orders:', error);
    }
  };

  // 组件挂载时获取所有订单列表
  useEffect(() => {
    fetchOrders(); // 获取所有订单
  }, []);

  // 当用户登录状态改变时获取用户订单
  useEffect(() => {
    if (isLoaded && user?.emailAddresses?.[0]?.emailAddress) {
      fetchOrders(user.emailAddresses[0].emailAddress);
    }
  }, [isLoaded, user]);

  const addOrder = (order: Order) => {
    setAllOrders((prev) => [...prev, order]);
  };

  const adduserOrder = (order: Order) => {
    setUserOrders((prev) => [...prev, order]);
  };

  const updateOrderStatus = async (orderId: string, status: Order['status']) => {
    setAllOrders((prev) =>
      prev.map((order) =>
        order.id === orderId ? { ...order, status } : order
      )
    );
    setUserOrders((prev) =>
      prev.map((order) =>
        order.id === orderId ? { ...order, status } : order
      )
    );
    // 可选：在状态更新后重新获取任务列表
    await fetchOrders();
  };

  const deductPoints = (points: number) => {
    setUserPoints((prev) => prev - points);
  };

  return (
    <VideoContext.Provider
      value={{
        orders: allOrders,
        userOrders,
        userPoints,
        videoStyles: defaultVideoStyles,
        addOrder,
        adduserOrder,
        updateOrderStatus,
        deductPoints,
        fetchOrders,
      }}
    >
      {children}
    </VideoContext.Provider>
  );
}
