import { Routes, Route, Navigate } from 'react-router-dom';
import { ClerkProvider, SignIn } from '@clerk/clerk-react';
import { useUser } from '@clerk/clerk-react';
import Navbar from './components/Navbar';
import LandingPage from './pages/LandingPage_2';
import SignInPage from './pages/auth/sign-in';
import SignUpPage from './pages/auth/sign-up';
import Dashboard from './pages/ConsumerDashboard';
import ProducerDashboard from './pages/ProducerDashboard';
import { VideoProvider } from './context/VideoContext';
import { dark, neobrutalism } from '@clerk/themes';
import './styles/style.css';

const publishableKey = import.meta.env.VITE_CLERK_PUBLISHABLE_KEY;

// Protected route component
function ProtectedRoute({ children }: { children: React.ReactNode }) {
  const { isSignedIn, isLoaded } = useUser();

  if (!isLoaded) {
    return <div>Loading...</div>;
  }

  if (!isSignedIn) {
    return <Navigate to="/sign-in" />;
  }

  return <>{children}</>;
}

function App() {
  return (
    <ClerkProvider
      publishableKey={publishableKey}
      appearance={{
        baseTheme: dark,
        variables: { colorPrimary: '#624CF5' }
      }}
      signInUrl="/sign-in"
      signUpUrl="/sign-up"
    >
      <VideoProvider>
        <div className="min-h-screen bg-gray-100">
          {/* <Navbar /> */}
          <Routes>
            <Route path="/" element={<LandingPage />} />
            <Route path="/sign-in/*" element={<SignInPage />} />
            <Route path="/sign-up/*" element={<SignUpPage />} />
            <Route path="/sign-in/sso-callback" element={
              <div className="flex min-h-screen items-center justify-center bg-white">
                <SignIn 
                  path="/sign-in/sso-callback"
                  signUpUrl="/sign-up"
                  fallbackRedirectUrl="/dashboard"
                  appearance={{
                    baseTheme: neobrutalism,
                    layout: {
                      socialButtonsPlacement: "bottom",
                      socialButtonsVariant: "blockButton",
                      privacyPageUrl: "https://clerk.dev/privacy",
                      termsPageUrl: "https://clerk.dev/terms"
                    },
                    variables: {
                      colorBackground: '#ffffff',
                      colorPrimary: '#624CF5',
                      colorText: '#1a1a1a',
                      colorInputBackground: '#ffffff',
                      colorInputText: '#1a1a1a',
                    },
                    elements: {
                      card: 'bg-white shadow-xl',
                      rootBox: 'bg-white',
                      formButtonPrimary: 'bg-primary-500 hover:bg-primary-600',
                      formFieldInput: 'bg-white border border-gray-200 focus:border-primary-500',
                      identityPreviewText: 'text-gray-700',
                      identityPreviewEditButton: 'text-primary-500',
                      socialButtonsIconButton: 'hover:bg-primary-50',
                      dividerLine: 'bg-gray-200',
                      dividerText: 'text-gray-500'
                    }
                  }}
                />
              </div>
            } />
            <Route
              path="/dashboard"
              element={
                <ProtectedRoute>
                  <Dashboard />
                </ProtectedRoute>
              }
            />
            <Route
              path="/producer-dashboard"
              element={
                <ProtectedRoute>
                  <ProducerDashboard />
                </ProtectedRoute>
              }
            />
          </Routes>
        </div>
      </VideoProvider>
    </ClerkProvider>
  );
}

export default App;