import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useUser, UserButton } from '@clerk/clerk-react';
import { neobrutalism } from '@clerk/themes';

export default function LandingPage_2() {
  const navigate = useNavigate();
  const { isSignedIn } = useUser();

  const handleAuthAction = () => {
    if (isSignedIn) {
      navigate('/dashboard');
    } else {
      navigate('/sign-in');
    }
  };

  const handleTryFree = () => {
    if (isSignedIn) {
      navigate('/dashboard');
    } else {
      navigate('/sign-in');
    }
  };

  const videoSamples = [
    {
      platform: 'Amazon',
      url: 'https://test-zhanggongqing-001.s3.amazonaws.com/ce1a4a42-d67d-4940-b35b-8bc0db23a3c6.mp4?AWSAccessKeyId=AKIAZ3OF3AHC5G2GZ5E3&Signature=KQvKX2h7Kc6eXN6zHqXh1aCL32Y%3D&Expires=1733412011',
      title: 'Product Demo Video',
      duration: '2:15'
    },
    {
      platform: 'TikTok',
      url: 'https://test-zhanggongqing-001.s3.amazonaws.com/ed532132-1a4c-41bc-952f-08b11b6d9555.mp4?AWSAccessKeyId=AKIAZ3OF3AHC5G2GZ5E3&Signature=ul7RN5W9fGMU5qmX6PmxO1z28Kg%3D&Expires=1733412014',
      title: 'Quick Tutorial',
      duration: '0:45'
    },
    {
      platform: 'Shopify',
      url: 'https://test-zhanggongqing-001.s3.amazonaws.com/bf85f26e-8dab-434a-818b-7f17fe6e3b92.mp4?AWSAccessKeyId=AKIAZ3OF3AHC5G2GZ5E3&Signature=9YOB4jFb2G63KvCO6HfIBJhCLfQ%3D&Expires=1733412023',
      title: 'Brand Story',
      duration: '1:30'
    },
  ]

  const videoSamples_zn = [
    {
      platform: '京东',
      url: 'https://test-zhanggongqing-001.s3.amazonaws.com/a296b9e7-2a07-44b5-bddc-da703f4f63bf.mp4?AWSAccessKeyId=AKIAZ3OF3AHC5G2GZ5E3&Signature=GmIECLp4IrnofJBqtmg8zMepY4Y%3D&Expires=1733412004',
      title: 'Product Demo Video',
      duration: '2:15'
    },
    {
      platform: '淘宝',
      url: 'https://test-zhanggongqing-001.s3.amazonaws.com/33bbb150-2720-4d10-aa91-e74fcb3a94f1.mp4?AWSAccessKeyId=AKIAZ3OF3AHC5G2GZ5E3&Signature=VcEG1tYGGv9b7SGbSsRaEpZ5gsk%3D&Expires=1733412019',
      title: 'Quick Tutorial',
      duration: '0:45'
    },
    {
      platform: '淘宝',
      url: 'https://test-zhanggongqing-001.s3.amazonaws.com/8b173e1d-974d-480d-97c1-6f841983a8e9.mp4?AWSAccessKeyId=AKIAZ3OF3AHC5G2GZ5E3&Signature=VBaOlTnsAGr9vuBhVQbQUbYlSLo%3D&Expires=1733412033',
      title: 'Brand Story',
      duration: '1:30'
    },
  ]

  return (
    <>
      <header className="hero">
        <nav className="nav">
          <div className="container">
            <img src="/logo.png" alt="PocketMgc" className="nav-logo" />
            <div className="nav-right">
              {isSignedIn ? (
                <div className="flex items-center gap-4">
                  <UserButton
                    afterSignOutUrl="/"
                    appearance={{
                      baseTheme: neobrutalism,
                      variables: {
                        colorBackground: '#ffffff',
                        colorText: '#000000',
                        colorPrimary: '#624CF5',
                        colorTextSecondary: '#000000',
                        colorTextOnPrimaryBackground: '#ffffff',
                      },
                      elements: {
                        avatarBox: "w-8 h-8",
                        userButtonPopoverCard: {
                          background: '#ffffff',
                          border: '1px solid #e5e7eb',
                        },
                        userButtonPopoverActionButton: {
                          color: '#000000',
                          background: '#ffffff',
                          '&:hover': {
                            background: '#f3f4f6',
                          },
                        },
                        userButtonPopoverActionButtonText: {
                          color: '#000000',
                        },
                        userButtonPopoverActionButtonIcon: {
                          color: '#000000',
                        }
                      }
                    }}
                  />
                  <Link to="/producer-dashboard" className="button button-primary">我的工作台</Link>
                </div>
              ) : (
                <>
                  <button onClick={handleAuthAction} className="nav-link">登录</button>
                  {/* <button onClick={handleAuthAction} className="button button-primary">我的工作台</button> */}
                </>
              )}
            </div>
          </div>
        </nav>
        <div className="container">
          <h1 className="hero-title">轻松获得服装商品的电商视频</h1>
          <p className="hero-subtitle">专注服装商家需求的AI视频服务</p>
          <p className="hero-desc">所有细节均由电商平台提供</p>
          <button onClick={handleTryFree} className="button button-primary">免费试用</button>
        </div>

        <div className="platform-icons">
          <img src="/video-platform.png" alt="平台图标" />
        </div>
      </header>

      <section className="ai-video">
        <div className="container">
          <div className="section-header">
            <h2 className="section-title text-center">独创AI电商视频生成方式</h2>
            <div className="text-center">
              <button onClick={handleTryFree} className="button button-primary">免费试用</button>
            </div>
          </div>
          <div className="video-demo">
            <h3 className="video-demo-title">操作简单一步</h3>
            <p className="video-demo-text">上传5张照片，既得上传的商品移植并且适时检测更新，今特公示，即可获取您的最终视频</p>
            <video controls className="demo-video">
              <source src="/assets/videos/finshed-video.mp4" type="video/mp4" />
              您的浏览器不支持视频标签。
            </video>
          </div>
        </div>
      </section>

      <section className="overseas">
        <div className="container">
          <div className="overseas-content">
            <div className="overseas-videos">
              {videoSamples.map((video, index) => (
                <div key={index} className="overseas-video-card">
                  <video controls className="overseas-video">
                    <source src={video.url} type="video/mp4" />
                    您的浏览器不支持视频标签。
                  </video>
                  <div className="video-info">
                    <span className="platform">{video.platform}</span>
                    <span className="duration">{video.duration}</span>
                  </div>
                </div>
              ))}
            </div>
            <div className="overseas-info">
              <h2 className="overseas-title">海外平台覆盖</h2>
              <div className="overseas-features">
                <p>符合各平台的视频风格</p>
                <p>已上线渠道，让带货直播效好</p>
                <p>轻松实现免模特</p>
                <p>轻松实现实拍展</p>
              </div>
              <button onClick={handleTryFree} className="button button-primary">免费试用</button>
            </div>
          </div>
          <div className="platform-logos">
            <div className="platform-label">可发布平台</div>
            <div className="platform-icons">
              <img src="/Amazon Icon.svg" alt="Amazon" />
              <img src="/Shopify Logo Icon.svg" alt="Shopify" />
              <img src="/Shein Logo.svg" alt="SHEIN" />
              <img src="/tiktok-seeklogo.svg" alt="TikTok" />
            </div>
          </div>
        </div>
      </section>

      <section className="national">
        <div className="container">
          <div className="national-content">
            <div className="national-info">
              <h2 className="national-title">国内平台覆盖</h2>
              <div className="national-features">
                <p>符合各平台的视频风格</p>
                <p>已上线渠道，让带货直播效好</p>
                <p>轻松实现免模特</p>
                <p>轻松实现实拍展</p>
              </div>
              <button onClick={handleTryFree} className="button button-primary">免费试用</button>
            </div>
            <div className="national-videos">
              {videoSamples_zn.map((video, index) => (
                <div key={index} className="national-video-card">
                  <video controls className="national-video">
                    <source src={video.url} type="video/mp4" />
                    您的浏览器不支持视频标签。
                  </video>
                  <div className="video-info">
                    <span className="platform">{video.platform}</span>
                    <span className="duration">{video.duration}</span>
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div className="platform-logos">
            <div className="platform-label">可发布平台</div>
            <div className="platform-icons">
              <img src="/Douyin Logo.svg" alt="抖音" />
              <img src="/快手 APP 图标.png" alt="快手" />
              <img src="/Taobao Logo.svg" alt="淘宝" />
              <img src="/Logo SVG.svg" alt="京东" />
            </div>
          </div>
        </div>
      </section>

      <section className="features">
        <div className="container">
          <div className="section-header">
            <h2 className="section-title text-center">定制化视频制作能力</h2>
            <div className="text-center">
              <button onClick={handleTryFree} className="button button-primary">免费试用</button>
            </div>
          </div>
          <div className="feature-grid">
            {[
              { title: '专业剪辑', desc: '专业视频剪辑，为您定制专属视频。从构思到制作，全程把控质量。' },
              { title: '更换模特', desc: '多样化的模特选择，满足不同风格需求。' },
              { title: '更换场景', desc: '丰富的场景库，打造独特视觉效果。' },
              { title: '无限照片', desc: '支持批量上传，高效处理大量素材。' }
            ].map((feature, index) => (
              <div key={index} className="feature">
                <h3>{feature.title}</h3>
                <p>{feature.desc}</p>
              </div>
            ))}
          </div>
        </div>
      </section>

      <footer className="footer">
        <div className="footer-content">
          <div className="left-section">
            <button onClick={handleTryFree} className="free-trial-btn">免费试用</button>
            <Link to="/" className="logo">
              <div className="logo-circle">
                <span>P</span>
              </div>
              PocketAigc
            </Link>
          </div>
          <div className="contact-info">
            <div>Mail：pocketaigc@gmail.com</div>
            <div>Tel：+86 15313833839</div>
            <div>Wechat：15313833839</div>
          </div>
          <div className="legal-links">
            <div><Link to="/privacy">Privacy Policy</Link></div>
            <div><Link to="/terms">Terms of Service</Link></div>
            <div><Link to="/legal">Legal Center</Link></div>
          </div>
        </div>
        <div className="bottom-text">
          2024 Pocketaigc, INC.    Design by CyberZus
        </div>
      </footer>
    </>
  );
}



