import { SignIn } from '@clerk/clerk-react';
import { neobrutalism } from '@clerk/themes';

export default function SignInPage() {
  return (
    <div className="flex min-h-screen items-center justify-center bg-white">
      <SignIn
        appearance={{
          baseTheme: neobrutalism,
          layout: {
            socialButtonsPlacement: "bottom",
            socialButtonsVariant: "blockButton",
            privacyPageUrl: "https://clerk.dev/privacy",
            termsPageUrl: "https://clerk.dev/terms"
          },
          variables: {
            colorBackground: '#ffffff',
            colorPrimary: '#624CF5',
            colorText: '#1a1a1a',
            colorInputBackground: '#ffffff',
            colorInputText: '#1a1a1a',
          },
          elements: {
            card: 'bg-white shadow-xl',
            rootBox: 'bg-white',
            formButtonPrimary: 'bg-primary-500 hover:bg-primary-600',
            formFieldInput: 'bg-white border border-gray-200 focus:border-primary-500',
            identityPreviewText: 'text-gray-700',
            identityPreviewEditButton: 'text-primary-500',
            socialButtonsIconButton: 'hover:bg-primary-50',
            dividerLine: 'bg-gray-200',
            dividerText: 'text-gray-500'
          }
        }}
        routing="path"
        path="/sign-in"
        signUpUrl="/sign-up"
        fallbackRedirectUrl="/dashboard"
      />
    </div>
  );
}