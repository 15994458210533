import React, { useState } from 'react';
import { Upload, Sparkles, ChevronDown, ChevronRight } from 'lucide-react';
import { useVideo } from '../context/VideoContext';
import { motion, AnimatePresence } from 'framer-motion';
import { useUser } from '@clerk/clerk-react';

export default function TaskCreation() {
  const [files, setFiles] = useState<FileList | null>(null);
  const [requirements, setRequirements] = useState('');
  const [deadline, setDeadline] = useState('');
  const [videoStyle, setVideoStyle] = useState('');
  const [duration, setDuration] = useState('3');
  const [isStyleSelectorOpen, setIsStyleSelectorOpen] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [platform, setPlatform] = useState('');
  const [platformUrl, setPlatformUrl] = useState('');

  const platforms = [
    { id: 'taobao-jd', name: '淘宝京东' },
    { id: 'douyin-tiktok', name: '抖音TikTok' },
    { id: 'amazon-shopify', name: 'Amazon，Shopify' },
    { id: 'offline', name: '线下及其他' }
  ];

  const { addOrder, videoStyles, deductPoints, userPoints, adduserOrder } = useVideo();
  const { user } = useUser();

  const selectedStyle = videoStyles.find(style => style.id === videoStyle);

  const uploadFileToS3 = async (file: File) => {
    try {
      // 1. 获取上传用的预签名 URL
      const response = await fetch('/api/tasks', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          type: 'get_upload_url',
          fileName: file.name,
          contentType: file.type
        }),
      });

      if (!response.ok) {
        throw new Error(`Failed to get upload URL: ${response.statusText}`);
      }

      const { url: uploadUrl, key } = await response.json();

      // 2. 使用预签名 URL 上传文件
      const uploadResponse = await fetch(uploadUrl, {
        method: 'PUT',
        body: file,
        headers: {
          'Content-Type': file.type
        },
      });

      if (!uploadResponse.ok) {
        throw new Error(`Failed to upload file: ${uploadResponse.statusText}`);
      }

      // 3. 获取公开访问的 URL
      const getUrlResponse = await fetch('/api/tasks', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          type: 'get_access_url',
          key: key
        }),
      });

      if (!getUrlResponse.ok) {
        throw new Error(`Failed to get access URL: ${getUrlResponse.statusText}`);
      }

      const { url: accessUrl } = await getUrlResponse.json();

      return {
        url: accessUrl,
        name: file.name,
        size: file.size,
        type: file.type,
        key: key
      };
    } catch (error) {
      console.error('Error uploading file:', error);
      throw error;
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!selectedStyle || !files || files.length === 0) {
      console.error('请选择视频风格并上传图片');
      return;
    }

    setIsSubmitting(true);
    try {
      // 上传所有文件到 S3
      const uploadPromises = Array.from(files).map(file => uploadFileToS3(file));
      const uploadedFiles = await Promise.all(uploadPromises);

      // 准备任务数据
      const taskTemplate = {
        user_email: user?.primaryEmailAddress?.emailAddress || '',
        description: requirements,
        template_name: `Task-${Date.now()}`,
        template_id: `${Date.now()}_${Math.random().toString(36).substring(2, 10)}`,
        edit_script_input: requirements,
        edit_video_style_input: videoStyle,
        video_duration: parseInt(duration),
        deadline: deadline,
        points_cost: selectedStyle.points,
        user_points: userPoints-selectedStyle.points,
        images: uploadedFiles.map(file => ({
          url: file.url,
          name: file.name,
          type: file.type,
          size: file.size,
          key: file.key
        })),
        platform: platform,
        platform_url: platformUrl,
        created_at: new Date().toISOString(),
        status: 'pending',
      };

      // 调用API创建任务
      const response = await fetch('/api/tasks', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(taskTemplate),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const result = await response.json();
      
      if (result.success) {
        // 扣除积分并更新UI状态
        deductPoints(selectedStyle.points);
        adduserOrder({
          user_email: taskTemplate.user_email,
          id: taskTemplate.template_id,
          images: uploadedFiles, // 现在 uploadedFiles 符合 ImageData[] 类型
          requirements,
          deadline,
          status: 'pending',
          style: videoStyle,
          platform: taskTemplate.platform,
        });

        // 重置表单
        setFiles(null);
        setRequirements('');
        setDeadline('');
        setVideoStyle('');
        setPlatform('');
        setPlatformUrl('');
      } else {
        console.error('Failed to create task:', result.error);
        // 这里可以添加错误提示UI
      }
    } catch (error) {
      console.error('Error submitting task:', error);
      // 这里可以添加错误提示UI
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="h-full overflow-y-auto p-6">
      <div className="mb-6">
        <h2 className="text-2xl font-bold text-white mb-2">创建新任务</h2>
        <p className="text-gray-400">请上传单个商品，至少五张模特图片，包括正面、背面、侧面、细节展示。然后选择视频要求开始制作</p>
      </div>
      
      <form onSubmit={handleSubmit} className="space-y-6">
        <div className="space-y-4">
          <motion.div 
            whileHover={{ scale: 1.01 }}
            className="border-2 border-dashed border-gray-600 rounded-xl bg-gray-700/30 p-8"
          >
            <input
              type="file"
              multiple
              accept="image/*"
              onChange={(e) => setFiles(e.target.files)}
              className="hidden"
              id="image-upload"
            />
            <label
              htmlFor="image-upload"
              className="cursor-pointer flex flex-col items-center"
            >
              <Upload className="h-12 w-12 text-indigo-400" />
              <span className="mt-2 text-sm text-gray-300">
                拖拽文件到此处或点击上传
              </span>
              <span className="mt-1 text-xs text-gray-500">
                支持格式：JPG、PNG、WEBP
              </span>
            </label>
          </motion.div>

          {files && (
            <motion.div 
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              className="grid grid-cols-2 gap-3"
            >
              {Array.from(files).map((file, index) => (
                <motion.div
                  key={index}
                  whileHover={{ scale: 1.05 }}
                  className="relative aspect-square bg-gray-700 rounded-lg overflow-hidden group"
                >
                  <img
                    src={URL.createObjectURL(file)}
                    alt={`Preview ${index + 1}`}
                    className="w-full h-full object-cover"
                  />
                  <div className="absolute inset-0 bg-indigo-500/20 opacity-0 group-hover:opacity-100 transition-opacity" />
                </motion.div>
              ))}
            </motion.div>
          )}

          <div className="space-y-2">
            <label className="block text-sm font-medium text-gray-300">
              视频风格
            </label>
            <div className="relative">
              <button
                type="button"
                onClick={() => setIsStyleSelectorOpen(!isStyleSelectorOpen)}
                className="w-full flex items-center justify-between bg-gray-700/50 text-left px-4 py-3 rounded-lg border border-gray-600 hover:border-purple-500 transition-colors"
              >
                <div className="flex items-center space-x-3">
                  {selectedStyle ? (
                    <>
                      {/* <img
                        src={selectedStyle.previewImage}
                        alt={selectedStyle.name}
                        className="w-8 h-8 rounded object-cover"
                      /> */}
                      <span className="text-white">{selectedStyle.name}</span>
                    </>
                  ) : (
                    <span className="text-gray-400">选择视频风格</span>
                  )}
                </div>
                <ChevronDown className={`w-5 h-5 text-gray-400 transition-transform ${
                  isStyleSelectorOpen ? 'rotate-180' : ''
                }`} />
              </button>

              <AnimatePresence>
                {isStyleSelectorOpen && (
                  <motion.div
                    initial={{ opacity: 0, y: -10 }}
                    animate={{ opacity: 1, y: 0 }}
                    exit={{ opacity: 0, y: -10 }}
                    className="absolute z-50 w-full mt-2 bg-gray-800 rounded-lg border border-gray-700 shadow-xl overflow-hidden"
                  >
                    <div className="max-h-[400px] overflow-y-auto">
                      {videoStyles.map((style) => (
                        <button
                          key={style.id}
                          type="button"
                          onClick={() => {
                            setVideoStyle(style.id);
                            setIsStyleSelectorOpen(false);
                          }}
                          className="w-full flex items-center space-x-3 px-4 py-3 hover:bg-gray-700/50 transition-colors"
                        >
                          {/* <img
                            src={style.previewImage}
                            alt={style.name}
                            className="w-12 h-12 rounded object-cover"
                          /> */}
                          <div className="flex-1 text-left">
                            <p className="text-white font-medium">{style.name}</p>
                            <p className="text-sm text-purple-300">{style.points} 积分</p>
                          </div>
                          <ChevronRight className="w-5 h-5 text-gray-400" />
                        </button>
                      ))}
                    </div>
                  </motion.div>
                )}
              </AnimatePresence>
            </div>
          </div>

          <motion.div whileHover={{ scale: 1.01 }}>
            <label className="block text-sm font-medium text-gray-300 mb-2">
              视频发布平台
            </label>
            <div className="grid grid-cols-2 gap-3">
              {platforms.map((p) => (
                <button
                  key={p.id}
                  type="button"
                  onClick={() => setPlatform(p.id)}
                  className={`px-4 py-2 rounded-lg text-sm font-medium transition-colors ${
                    platform === p.id
                      ? 'bg-indigo-600 text-white'
                      : 'bg-gray-700 text-gray-300 hover:bg-gray-600'
                  }`}
                >
                  {p.name}
                </button>
              ))}
            </div>
          </motion.div>

          <motion.div whileHover={{ scale: 1.01 }}>
            <label className="block text-sm font-medium text-gray-300 mb-2">
              视频发布平台链接（可选）
            </label>
            <input
              type="url"
              value={platformUrl}
              onChange={(e) => setPlatformUrl(e.target.value)}
              placeholder="请输入产品链接"
              className="w-full px-4 py-2 bg-gray-700 border border-gray-600 rounded-lg text-white placeholder-gray-400 focus:outline-none focus:border-indigo-500 transition-colors"
            />
          </motion.div>

          <motion.div whileHover={{ scale: 1.01 }}>
            <label className="block text-sm font-medium text-gray-300 mb-2">
              制作要求
            </label>
            <textarea
              value={requirements}
              onChange={(e) => setRequirements(e.target.value)}
              className="w-full bg-gray-700/50 border-gray-600 rounded-lg text-gray-200 focus:ring-indigo-500 focus:border-indigo-500 p-3"
              rows={3}
              placeholder="请描述您的具体要求..."
            />
          </motion.div>

          <motion.div whileHover={{ scale: 1.01 }}>
            <label className="block text-sm font-medium text-gray-300 mb-2">
              期望交付日期
            </label>
            <input
              type="date"
              value={deadline}
              onChange={(e) => setDeadline(e.target.value)}
              className="w-full bg-gray-700/50 border-gray-600 rounded-lg text-gray-200 focus:ring-indigo-500 focus:border-indigo-500 p-3"
            />
          </motion.div>
        </div>

        <motion.button
          whileHover={{ scale: 1.02 }}
          whileTap={{ scale: 0.98 }}
          type="submit"
          className="w-full bg-gradient-to-r from-indigo-600 to-purple-600 text-white py-3 px-4 rounded-lg focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 focus:ring-offset-gray-800 transition-all disabled:opacity-50 disabled:cursor-not-allowed font-medium"
          disabled={!files || files.length === 0 || !videoStyle || isSubmitting}
        >
          {isSubmitting ? (
            <>
              <svg className="animate-spin -ml-1 mr-3 h-5 w-5 inline-block" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
              </svg>
              生成中...
            </>
          ) : (
            <>
              <Sparkles className="w-5 h-5 inline-block mr-2" />
              开始生成
            </>
          )}
        </motion.button>
      </form>
    </div>
  );
}
