import React, { useRef, useState, useEffect } from 'react';
import { ChevronLeft, ChevronRight, Coins } from 'lucide-react';
import { useVideo } from '../context/VideoContext';
import { motion, AnimatePresence } from 'framer-motion';
import TaskCreation from '../components/TaskCreation';
import GenerationResults from '../components/GenerationResults';

export default function ConsumerDashboard() {
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [leftPanelWidth, setLeftPanelWidth] = useState(400);
  
  const { userPoints } = useVideo();
  
  const resizerRef = useRef<HTMLDivElement>(null);
  const isDraggingRef = useRef(false);

  useEffect(() => {
    const handleMouseMove = (e: MouseEvent) => {
      if (!isDraggingRef.current) return;
      
      const newWidth = e.clientX;
      if (newWidth >= 300 && newWidth <= window.innerWidth * 0.7) {
        setLeftPanelWidth(newWidth);
      }
    };

    const handleMouseUp = () => {
      isDraggingRef.current = false;
      document.body.style.cursor = 'default';
      window.removeEventListener('mousemove', handleMouseMove);
      window.removeEventListener('mouseup', handleMouseUp);
    };

    window.addEventListener('mousemove', handleMouseMove);
    window.addEventListener('mouseup', handleMouseUp);

    return () => {
      window.removeEventListener('mousemove', handleMouseMove);
      window.removeEventListener('mouseup', handleMouseUp);
    };
  }, []);

  const handleMouseDown = () => {
    isDraggingRef.current = true;
    document.body.style.cursor = 'col-resize';
  };

  return (
    <div className="min-h-[calc(100vh-4rem)] bg-gradient-to-br from-gray-900 via-gray-800 to-indigo-900 flex">
      {/* Points Display */}
      <div className="fixed top-4 right-4 z-20 bg-gray-800/90 backdrop-blur-sm px-4 py-2 rounded-full border border-gray-700 shadow-lg">
        <div className="flex items-center space-x-2">
          <Coins className="w-5 h-5 text-yellow-400" />
          <span className="text-white font-medium">{userPoints}</span>
          <span className="text-gray-400">积分</span>
        </div>
      </div>

      {/* Left Panel */}
      <AnimatePresence initial={false}>
        {!isCollapsed && (
          <motion.div
            initial={{ width: 0, opacity: 0 }}
            animate={{ width: leftPanelWidth, opacity: 1 }}
            exit={{ width: 0, opacity: 0 }}
            transition={{ duration: 0.3 }}
            className="relative bg-gray-800/50 backdrop-blur-sm border-r border-gray-700/50"
            style={{ width: leftPanelWidth }}
          >
            <TaskCreation />
            {/* Resizer */}
            <div
              ref={resizerRef}
              className="absolute top-0 right-0 w-1 h-full cursor-col-resize bg-gray-700/50 hover:bg-indigo-500/50 transition-colors"
              onMouseDown={handleMouseDown}
            />
          </motion.div>
        )}
      </AnimatePresence>

      {/* Toggle Button */}
      <button
        onClick={() => setIsCollapsed(!isCollapsed)}
        className="absolute left-0 top-1/2 -translate-y-1/2 z-10 bg-gray-800 text-white p-2 rounded-r-lg shadow-lg hover:bg-gray-700 transition-colors"
        style={{ left: isCollapsed ? '0' : leftPanelWidth }}
      >
        {isCollapsed ? (
          <ChevronRight className="w-5 h-5" />
        ) : (
          <ChevronLeft className="w-5 h-5" />
        )}
      </button>

      {/* Right Panel */}
      <GenerationResults />
    </div>
  );
}