import React, { useState } from 'react';
import { Clock, CheckCircle, XCircle, Download, Upload, ChevronRight, Calendar, Image as ImageIcon, Globe } from 'lucide-react';
import { useVideo } from '../context/VideoContext';
import { motion, AnimatePresence } from 'framer-motion';

interface ImageData {
  url: string;
  name: string;
  size: number;
  type: string;
}

interface TaskDetailProps {
  order: {
    id: string;
    user_email: string;
    images: ImageData[];
    requirements: string;
    deadline: string;
    status: 'pending' | 'completed';
    platform: string;
    platform_url: string;
  };
  onClose: () => void;
  onUpload: (orderId: string, file: File) => void;
}

const TaskDetail = ({ order, onClose, onUpload }: TaskDetailProps) => {
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSubmitVideo = async (orderId: string) => {
    if (!selectedFile) return;

    setIsSubmitting(true);
    try {
      // 添加文件大小检查
      const fileSizeInMB = selectedFile.size / (1024 * 1024);
      if (fileSizeInMB > 1000) { // 1000MB 限制
        throw new Error('视频文件大小不能超过 1000MB');
      }

      // 1. 获取上传用的预签名 URL
      const getUrlResponse = await fetch('/api/tasks', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          type: 'get_upload_url',
          fileName: selectedFile.name,
          contentType: selectedFile.type
        }),
      });

      if (!getUrlResponse.ok) {
        throw new Error(`Failed to get upload URL: ${getUrlResponse.statusText}`);
      }

      const { url: uploadUrl, key } = await getUrlResponse.json();

      // 2. 使用预签名 URL 上传视频
      const uploadResponse = await fetch(uploadUrl, {
        method: 'PUT',
        body: selectedFile,
        headers: {
          'Content-Type': selectedFile.type
        },
      });

      if (!uploadResponse.ok) {
        throw new Error(`Failed to upload video: ${uploadResponse.statusText}`);
      }

      // 3. 获取访问 URL
      const getAccessUrlResponse = await fetch('/api/tasks', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          type: 'get_access_url',
          key: key
        }),
      });

      if (!getAccessUrlResponse.ok) {
        throw new Error(`Failed to get access URL: ${getAccessUrlResponse.statusText}`);
      }

      const { url: accessUrl } = await getAccessUrlResponse.json();

      // 4. 提交任务完成信息
      console.log('Submitting task completion:', {
        type: 'completion',
        template_id: orderId,
        key: key,
        url: accessUrl,
        filename: selectedFile.name,
        filesize: selectedFile.size,
        filetype: selectedFile.type
      });

      const response = await fetch('/api/tasks', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          type: 'completion',
          template_id: orderId,
          key: key,
          url: accessUrl,
          filename: selectedFile.name,
          filesize: selectedFile.size,
          filetype: selectedFile.type
        }),
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || '提交失败，请重试');
      }

      const result = await response.json();

      if (result.success) {
        onClose();
        // 刷新任务列表
        window.location.reload();
      } else {
        throw new Error(result.message || '提交失败，请重试');
      }
    } catch (error) {
      console.error('Error submitting video:', error);
      alert(error instanceof Error ? error.message : '提交失败，请重试');
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: 20 }}
      className="fixed inset-0 bg-black/40 backdrop-blur-sm z-50 flex items-center justify-center p-4"
    >
      <div className="bg-white rounded-xl max-w-4xl w-full max-h-[90vh] overflow-hidden shadow-xl">
        <div className="p-6 border-b border-gray-200">
          <div className="flex items-center justify-between">
            <h3 className="text-xl font-semibold text-gray-900">任务详情 #{order.id.slice(-4)}</h3>
            <button
              onClick={onClose}
              className="text-gray-500 hover:text-gray-700 transition-colors"
            >
              <XCircle className="h-6 w-6" />
            </button>
          </div>
        </div>
        
        <div className="p-6 overflow-y-auto max-h-[calc(90vh-120px)]">
          <div className="grid md:grid-cols-2 gap-6">
            <div className="space-y-6">
              <div>
                <h4 className="text-sm font-medium text-gray-600 mb-2">任务要求</h4>
                <p className="text-gray-800 bg-gray-50 rounded-lg p-4 border border-gray-100">{order.requirements}</p>
              </div>
              
              <div>
                <h4 className="text-sm font-medium text-gray-600 mb-2">发布平台</h4>
                <p className="text-gray-800 bg-gray-50 rounded-lg p-4 border border-gray-100">{order.platform || '未指定'}</p>
              </div>
              
              {order.platform_url && (
                <div>
                  <h4 className="text-sm font-medium text-gray-600 mb-2">平台链接</h4>
                  <a 
                    href={order.platform_url} 
                    target="_blank" 
                    rel="noopener noreferrer" 
                    className="text-indigo-400 hover:text-indigo-300 break-all bg-gray-50 rounded-lg p-4 border border-gray-100"
                  >
                    {order.platform_url}
                  </a>
                </div>
              )}
              
              <div>
                <h4 className="text-sm font-medium text-gray-600 mb-2">上传的图片素材</h4>
                <div className="grid grid-cols-2 gap-3">
                  {order.images.map((image, index) => (
                    <div key={index} className="aspect-square rounded-lg overflow-hidden bg-gray-50 border border-gray-100">
                      <img
                        src={image.url}
                        alt={`${image.name || `Reference ${index + 1}`}`}
                        className="w-full h-full object-cover"
                      />
                      <div className="p-2 bg-white bg-opacity-90 text-xs text-gray-600">
                        <p className="truncate">{image.name}</p>
                        <p>{(image.size / (1024 * 1024)).toFixed(2)} MB</p>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              
              <div>
                <h4 className="text-sm font-medium text-gray-600 mb-2">预生产视频素材</h4>
                <div className="bg-gray-50 rounded-lg p-4 border border-gray-100">
                  <video
                    className="w-full rounded-lg"
                    controls
                    src="https://example.com/preview.mp4"
                  />
                  <button className="mt-3 w-full flex items-center justify-center space-x-2 bg-purple-50 text-purple-600 py-2 rounded-lg hover:bg-purple-100 transition-colors">
                    <Download className="h-4 w-4" />
                    <span>下载预生产素材</span>
                  </button>
                </div>
              </div>
            </div>
            
            <div className="space-y-6">
              <div>
                <h4 className="text-sm font-medium text-gray-600 mb-2">任务状态</h4>
                <div className="bg-gray-50 rounded-lg p-4 border border-gray-100">
                  <div className="flex items-center justify-between">
                    <span className="text-gray-900">当前进度</span>
                    <span className={`px-3 py-1 rounded-full text-xs font-medium ${
                      order.status === 'completed'
                        ? 'bg-green-100 text-green-700'
                        : 'bg-yellow-100 text-yellow-700'
                    }`}>
                      {order.status === 'completed' ? '已完成' : '进行中'}
                    </span>
                  </div>
                  <div className="mt-4">
                    <div className="flex items-center justify-between text-sm text-gray-600 mb-2">
                      <span>预期交付时间</span>
                      <span>{order.deadline}</span>
                    </div>
                    <div className="w-full bg-gray-200 rounded-full h-2">
                      <div
                        className="bg-purple-600 h-full rounded-full"
                        style={{ width: order.status === 'completed' ? '100%' : '60%' }}
                      />
                    </div>
                  </div>
                </div>
              </div>
              
              {order.status === 'pending' && (
                <div>
                  <h4 className="text-sm font-medium text-gray-600 mb-2">提交成品</h4>
                  <div className="bg-gray-50 rounded-lg p-4 border border-gray-100">
                    <input
                      type="file"
                      accept="video/*"
                      onChange={(e) => {
                        const file = e.target.files?.[0];
                        if (file) setSelectedFile(file);
                      }}
                      className="hidden"
                      id={`final-video-${order.id}`}
                    />
                    <label
                      htmlFor={`final-video-${order.id}`}
                      className="flex flex-col items-center justify-center border-2 border-dashed border-gray-300 rounded-lg p-6 cursor-pointer hover:border-purple-400 transition-colors"
                    >
                      <Upload className="h-8 w-8 text-gray-400 mb-2" />
                      <span className="text-sm text-gray-600">点击上传成品视频</span>
                      <span className="text-xs text-gray-500 mt-1">支持 MP4, MOV 格式</span>
                    </label>
                    {selectedFile && (
                      <div className="mt-4">
                        <div className="flex items-center justify-between bg-white p-3 rounded-lg border border-gray-200">
                          <div className="flex items-center space-x-3">
                            <div className="p-2 bg-purple-50 rounded-lg">
                              <ImageIcon className="h-5 w-5 text-purple-600" />
                            </div>
                            <div>
                              <p className="text-sm font-medium text-gray-900">{selectedFile.name}</p>
                              <p className="text-xs text-gray-500">{(selectedFile.size / (1024 * 1024)).toFixed(2)} MB</p>
                            </div>
                          </div>
                        </div>
                        <button
                          onClick={() => handleSubmitVideo(order.id)}
                          className="mt-4 w-full bg-purple-600 text-white py-2 px-4 rounded-lg hover:bg-purple-700 transition-colors flex items-center justify-center space-x-2"
                          disabled={isSubmitting}
                        >
                          {isSubmitting ? (
                            <>
                              <div className="animate-spin rounded-full h-4 w-4 border-2 border-white border-t-transparent" />
                              <span>提交中...</span>
                            </>
                          ) : (
                            <>
                              <CheckCircle className="h-4 w-4" />
                              <span>确认提交</span>
                            </>
                          )}
                        </button>
                      </div>
                    )}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </motion.div>
  );
};

export default function ProducerDashboard() {
  const { orders, updateOrderStatus } = useVideo();
  const [selectedOrder, setSelectedOrder] = useState<any>(null);
  const [filterStatus, setFilterStatus] = useState<'all' | 'pending' | 'completed'>('all');

  const handleUpload = async (orderId: string, file: File) => {
    await new Promise(resolve => setTimeout(resolve, 1000));
    updateOrderStatus(orderId, 'completed');
    setSelectedOrder(null);
  };

  const sortedOrders = [...orders].sort((a, b) => 
    new Date(a.deadline).getTime() - new Date(b.deadline).getTime()
  );

  const filteredOrders = sortedOrders.filter(order => 
    filterStatus === 'all' ? true : order.status === filterStatus
  );

  return (
    <div className="min-h-screen bg-gradient-to-b from-purple-50 to-white py-8 px-4">
      <div className="max-w-7xl mx-auto">
        <div className="flex flex-col md:flex-row md:items-center md:justify-between mb-8">
          <div>
            <h1 className="text-3xl font-bold text-gray-900 mb-2">任务管理</h1>
            <p className="text-gray-600">管理和跟踪您的视频制作任务</p>
          </div>
          
          <div className="mt-4 md:mt-0 flex space-x-2">
            <button
              onClick={() => setFilterStatus('all')}
              className={`px-4 py-2 rounded-lg text-sm font-medium transition-colors ${
                filterStatus === 'all'
                  ? 'bg-purple-600 text-white'
                  : 'bg-white text-gray-600 hover:bg-gray-50 border border-gray-200'
              }`}
            >
              全部任务
            </button>
            <button
              onClick={() => setFilterStatus('pending')}
              className={`px-4 py-2 rounded-lg text-sm font-medium transition-colors ${
                filterStatus === 'pending'
                  ? 'bg-purple-600 text-white'
                  : 'bg-white text-gray-600 hover:bg-gray-50 border border-gray-200'
              }`}
            >
              进行中
            </button>
            <button
              onClick={() => setFilterStatus('completed')}
              className={`px-4 py-2 rounded-lg text-sm font-medium transition-colors ${
                filterStatus === 'completed'
                  ? 'bg-purple-600 text-white'
                  : 'bg-white text-gray-600 hover:bg-gray-50 border border-gray-200'
              }`}
            >
              已完成
            </button>
          </div>
        </div>

        <div className="grid gap-4">
          {filteredOrders.map((order) => (
            <motion.div
              key={order.id}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              className="bg-white rounded-xl border border-gray-200 overflow-hidden shadow-sm hover:shadow-md transition-shadow"
            >
              <div className="p-4 md:p-6">
                <div className="flex items-center justify-between">
                  <div className="flex items-center space-x-4">
                    <div className={`w-12 h-12 rounded-lg flex items-center justify-center ${
                      order.status === 'completed'
                        ? 'bg-green-100 text-green-700'
                        : 'bg-yellow-100 text-yellow-700'
                    }`}>
                      {order.status === 'completed' ? (
                        <CheckCircle className="h-6 w-6" />
                      ) : (
                        <Clock className="h-6 w-6" />
                      )}
                    </div>
                    <div>
                      <h3 className="text-lg font-semibold text-gray-900">
                        任务 #{order.id.slice(-4)}
                      </h3>
                      <p className="text-sm text-gray-500 mt-1">
                        提交用户: {order.user_email}
                      </p>
                      <div className="flex items-center space-x-2 mt-1">
                        <Calendar className="h-4 w-4 text-gray-400" />
                        <span className="text-sm text-gray-600">
                          截止日期: {order.deadline}
                        </span>
                      </div>
                    </div>
                  </div>
                  
                  <button
                    onClick={() => setSelectedOrder(order)}
                    className="flex items-center space-x-2 bg-purple-50 hover:bg-purple-100 text-purple-600 px-4 py-2 rounded-lg transition-colors"
                  >
                    <span>查看详情</span>
                    <ChevronRight className="h-4 w-4" />
                  </button>
                </div>
                
                <div className="mt-4 flex items-center space-x-4">
                  <div className="flex items-center space-x-2">
                    <ImageIcon className="h-4 w-4 text-gray-400" />
                    <span className="text-sm text-gray-600">
                      {order.images.length} 张参考图片
                    </span>
                  </div>
                  <div className="w-px h-4 bg-gray-200" />
                  {order.platform && (
                    <>
                      <div className="flex items-center space-x-2">
                        <Globe className="h-4 w-4 text-gray-400" />
                        <span className="text-sm text-gray-600">
                          {order.platform}
                        </span>
                      </div>
                      <div className="w-px h-4 bg-gray-200" />
                    </>
                  )}
                  <span className={`px-3 py-1 rounded-full text-xs font-medium ${
                    order.status === 'completed'
                      ? 'bg-green-100 text-green-700'
                      : 'bg-yellow-100 text-yellow-700'
                  }`}>
                    {order.status === 'completed' ? '已完成' : '进行中'}
                  </span>
                </div>
              </div>
            </motion.div>
          ))}
        </div>

        <AnimatePresence>
          {selectedOrder && (
            <TaskDetail
              order={selectedOrder}
              onClose={() => setSelectedOrder(null)}
              onUpload={handleUpload}
            />
          )}
        </AnimatePresence>
      </div>
    </div>
  );
}