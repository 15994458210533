import React from 'react';
import { Video, Settings, Clock } from 'lucide-react';
import { useVideo } from '../context/VideoContext';
import { motion } from 'framer-motion';

interface Order {
  id: string;
  status: string;
  deadline: string;
  videos: {
    url: string;
  }[];
}

export default function GenerationResults() {
  const { userOrders } = useVideo();
  console.log(userOrders);

  return (
    <div className="flex-1 p-6 overflow-y-auto">
      <div className="mb-6">
        <h2 className="text-2xl font-bold text-white mb-2">生成结果</h2>
        <p className="text-gray-400">查看已完成的视频和正在处理的任务</p>
      </div>

      <div className="space-y-6">
        {userOrders.length === 0 ? (
          <motion.div 
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            className="text-center py-12"
          >
            <Video className="h-16 w-16 text-gray-500 mx-auto mb-4" />
            <p className="text-gray-400">暂无生成记录</p>
            <p className="text-gray-500 text-sm mt-2">上传图片开始创建您的第一个视频</p>
          </motion.div>
        ) : (
          <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-6">
              {userOrders.map((order) => (
              <motion.div
                key={order.id}
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                className="bg-gray-700/50 rounded-lg p-4 border border-gray-600/50"
              >
                <div className="flex items-center justify-between mb-4">
                  <div className="flex items-center space-x-2">
                    <Settings className="h-4 w-4 text-indigo-400" />
                    <span className="text-sm text-gray-300">任务 #{order.id.slice(-4)}</span>
                  </div>
                  <span className={`px-3 py-1 rounded-full text-xs font-medium ${
                    order.status === 'completed'
                      ? 'bg-green-500/20 text-green-400 border border-green-500/30'
                      : 'bg-yellow-500/20 text-yellow-400 border border-yellow-500/30'
                  }`}>
                    {order.status === 'completed' ? '已完成' : '处理中'}
                  </span>
                </div>

                {order.status === 'completed' ? (
                  <motion.div 
                    whileHover={{ scale: 1.02 }}
                    className="aspect-video bg-black rounded-lg overflow-hidden"
                  >
                    {order.videos?.[0]?.url ? (
                      <video
                        className="w-full h-full object-contain"
                        controls
                        src={order.videos[0].url}
                        onError={(e) => {
                          console.error('Video loading error:', e);
                          e.currentTarget.style.display = 'none';
                          e.currentTarget.parentElement?.classList.add('flex', 'items-center', 'justify-center');
                          e.currentTarget.insertAdjacentHTML('afterend', 
                            '<div class="text-gray-400 text-sm">视频加载失败，请检查访问权限</div>'
                          );
                        }}
                      />
                    ) : (
                      <div className="flex items-center justify-center h-full text-gray-400 text-sm">
                        暂无视频
                      </div>
                    )}
                  </motion.div>
                ) : (
                  <div className="space-y-4">
                    <div className="w-full bg-gray-600/50 rounded-full h-2 overflow-hidden">
                      <motion.div
                        initial={{ width: 0 }}
                        animate={{ width: '66%' }}
                        transition={{ duration: 2, repeat: Infinity }}
                        className="bg-gradient-to-r from-indigo-500 to-purple-500 h-full rounded-full"
                      />
                    </div>
                    <div className="flex items-center justify-between text-sm">
                      <span className="text-gray-400 flex items-center">
                        <Clock className="h-4 w-4 mr-1" />
                        预计完成时间
                      </span>
                      <span className="text-gray-300">{order.deadline}</span>
                    </div>
                  </div>
                )}
              </motion.div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
}
