import { SignUp } from '@clerk/clerk-react';
import { neobrutalism } from '@clerk/themes';

export default function SignUpPage() {
  return (
    <div className="flex min-h-screen items-center justify-center bg-white">
      <SignUp
        appearance={{
          baseTheme: neobrutalism,
          variables: {
            colorBackground: '#ffffff',
            colorPrimary: '#624CF5',
            colorText: '#1a1a1a',
            colorInputBackground: '#ffffff',
            colorInputText: '#1a1a1a',
          },
          elements: {
            card: 'bg-white shadow-xl',
            rootBox: 'bg-white',
            formButtonPrimary: 'bg-primary-500 hover:bg-primary-600',
            formFieldInput: 'bg-white border border-gray-200 focus:border-primary-500',
            identityPreviewText: 'text-gray-700',
            identityPreviewEditButton: 'text-primary-500'
          }
        }}
        routing="path"
        path="/sign-up"
        signInUrl="/sign-in"
        fallbackRedirectUrl="/dashboard"
      />
    </div>
  );
}
